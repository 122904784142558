export const selectAddress = (state: any) => {
  const { user } = state
  const { address, city } = user

  if (address) {
    return { address, city }
  }
}

export const selectShippingAddress = (state: any) => {
  const { user } = state
  const { address, city, country, zip, address2, phone } = user

  return { address, city, state: user.state, country, zip, address2, phone }
}

export function selectUser(state: any) {
  const { user } = state

  return user
}

export const selectField = (state: any, field: string) => {
  const { user } = state

  return user[field]
}

export const selectFoundPet = (state: any) => {
  const { user } = state

  if (!user) {
    return
  }

  return user.foundPet
}
