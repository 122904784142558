import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import numeral from 'numeral'
import {
  useQuery,
} from 'react-query'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  Label,
  FieldGroup,
  Text,
  Header,
  Button,
  Loader,
  Link,
  Input,
  StripeInput,
  Fields,
} from '@pet-notify/ui'
import { useForm } from 'react-hook-form'

import type { ReplaceIntellitagOrder } from 'UserApp/services/replace-intellitag-orders/types'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import {
  useCheckoutReplaceIntellitagOrder,
} from 'UserApp/hooks/api/replace-intellitag-order'
import style from '../style.module.scss'
import { fetchPricing } from 'UserApp/services/replace-intellitag-orders'
import { Checkbox } from '@pet-notify/ui'
import api from 'services/pet-notify-api'

type FormData = {
  zip?: string
  saveCard?: boolean
}

type Props = {
  onSuccess: () => void
  replaceIntellitagOrder: ReplaceIntellitagOrder
}

function Checkout({ onSuccess, replaceIntellitagOrder }: Props) {
  const [error, setError] = useState<string>()
  const [isProcessing, setIsProcessing] = useState(false)
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [paymentRequestKey, setPaymentRequestKey] = useState(0)
  const paymentRequest = useRef()
  // const [pricingQuery, setPricingQuery] = useState(null)
  const elements = useElements()
  const stripe: any = useStripe()
  const id = replaceIntellitagOrder?.id
  const { register, handleSubmit } = useForm<FormData>()

  const [savedPaymentMethods, setSavedPaymentMethods] = useState([])
  const [showPaymentInfoForm, setShowPaymentInfoForm] = useState(true)
  const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod]: any =
    useState(null)
  const [isSavedCardSelected, setIsSavedCardSelected] = useState(false)

  useEffect(() => {
    if (selectedSavedPaymentMethod) {
      setShowPaymentInfoForm(false)
      setIsSavedCardSelected(true)
    } else {
      setShowPaymentInfoForm(true)
      setIsSavedCardSelected(false)
    }
  }, [selectedSavedPaymentMethod])

  async function fetchSavedPaymentMethods() {
    try {
      const response: any = await api.intellitagOrders.savedPaymentMethods()
      setSavedPaymentMethods(response)
    } catch (e) {
      console.error('Error fetching saved payment methods:', e)
    }
  }

  useEffect(() => {
    fetchSavedPaymentMethods()
  }, [])

  const handleSavedCardClick = (paymentMethod: any) => {
    if (paymentMethod.id === selectedSavedPaymentMethod?.id) {
      setSelectedSavedPaymentMethod(null)
      setShowPaymentInfoForm(true)
    } else {
      setSelectedSavedPaymentMethod(paymentMethod)
      setShowPaymentInfoForm(false)
    }
  }

  // const pricingQuery = useReplaceIntellitagOrderPricing(id)
  const pricingQuery = useQuery(['replace-intellitag-order-pricing', id], () =>
    fetchPricing(id),
  )
  const checkoutMutation = useCheckoutReplaceIntellitagOrder(id)

  // useEffect(() => {
  //   setPricingQuery(useReplaceIntellitagOrderPricing(id))
  // }, [id])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     window.location.reload();
  //   }, 1000); // 1000 milliseconds = 1 second

  //   return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  // }, []);

  const isPaymentRequired = pricingQuery?.data?.total > 0

  useEffect(() => {
    if (stripe && isPaymentRequired && pricingQuery?.data?.total) {
      const newPaymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Replace Intellitag',
          amount: pricingQuery.data.total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      newPaymentRequest.canMakePayment().then((result) => {
        if (result) {
          paymentRequest.current = newPaymentRequest
          setCanMakePayment(true)
          setPaymentRequestKey((prevKey) => prevKey + 1)
        } else {
          setCanMakePayment(false)
        }
      })

      // Handle token submission when using PaymentRequestButtonElement
      newPaymentRequest.on('token', async ({ complete, token }) => {
        setIsProcessing(true)

        try {
          checkoutMutation.mutate(
            { token: token.id },
            {
              onSuccess: () => {
                localStorage.removeItem('replace-intellitag-order-id')
                onSuccess()
              },
            },
          )
          setIsProcessing(false)
        } catch (e) {
          setIsProcessing(false)
          complete('fail')
          setError(e)
        }
      })
    }
  }, [stripe, isPaymentRequired, pricingQuery?.data?.total])

  async function onSubmit(data: FormData) {
    if (isPaymentRequired) {
      setIsProcessing(true)
      if (isSavedCardSelected) {
        checkoutMutation.mutate(
          { token: null, saveCard: data.saveCard, paymentMethodId: selectedSavedPaymentMethod?.id },
          {
            onSuccess: () => {
              localStorage.removeItem('replace-intellitag-order-id')
              onSuccess()
            },
          },
        )
        setIsProcessing(false)
      } else {
        const cardNumberElement = elements.getElement(CardNumberElement)

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement
        })

        if (error) {
          setError(error.message)
        } else {
          checkoutMutation.mutate(
            { token: paymentMethod.id, saveCard: data.saveCard, paymentMethodId: null },
            {
              onSuccess: () => {
                localStorage.removeItem('replace-intellitag-order-id')
                onSuccess()
              },
            },
          )
          setIsProcessing(false)
        }
      }
    } else {
      checkoutMutation.mutate(
        {},
        {
          onSuccess: () => {
            localStorage.removeItem('replace-intellitag-order-id')
            onSuccess()
          },
        },
      )
    }
  }

  if (pricingQuery?.isLoading) {
    return <Loader />
  }

  const pricing = pricingQuery?.data

  return (
    <>
      <Header mb='6'>Order details</Header>

      <div className={style.activeSubscription}>
        You have an active premium subscription
      </div>

      <div className={style.summary}>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>
            <p className={style.summaryTitle}>Intellitag</p>
            <p className={style.summaryDescription}>
              Size: {replaceIntellitagOrder.meta.size}
              <br />
              Name: {replaceIntellitagOrder.meta.nameOnTag}
              {replaceIntellitagOrder.meta.phone && (
                <>
                  <br />
                  Phone: {replaceIntellitagOrder.meta.phone}
                </>
              )}
            </p>
          </dt>
          <dd className={style.summaryPrice}>
            <Link
              as={RouterLink}
              to='/replace-intellitag'
              className={style.link}
            >
              Edit order
            </Link>
            {pricingQuery?.data?.subTotal
              ? numeral(
                pricingQuery?.data?.subTotal > 0 ? pricingQuery?.data?.subTotal / 100 : 0,
              ).format('$0.00')
              : 'Included in subscription'}
          </dd>
        </dl>

        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Shipping</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.shipping).format('$0.00')}
          </dd>
        </dl>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Tax</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.tax > 0 ? pricingQuery?.data?.tax / 100 : 0).format('$0.00')}
          </dd>
        </dl>
        <dl className={style.summaryLineItem}>
          <dt className={style.summaryLabel}>Total</dt>
          <dd className={style.summaryPrice}>
            {numeral(pricingQuery?.data?.total > 0 ? pricingQuery?.data?.total / 100 : 0).format(
              '$0.00',
            )}
          </dd>
        </dl>
      </div>

      {/* Render Payment Request Button if available */}
      {isPaymentRequired && paymentRequest && paymentRequest.current && canMakePayment && (
        <div className={style.paymentButtons} key={paymentRequestKey}>
          <PaymentRequestButtonElement
            options={{ paymentRequest: paymentRequest.current }}
          />
        </div>
      )}

      {savedPaymentMethods?.length > 0 && (
        <div style={{ marginBottom: "16px" }}>
          <Label>Saved Cards</Label>
          <div style={{marginTop: "8px", display: 'flex', gap: '16px', overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
            {savedPaymentMethods.map((paymentMethod: any) => (
              <div
                key={paymentMethod.id}
                style={{
                  cursor: 'pointer',
                  border: ` ${selectedSavedPaymentMethod?.id === paymentMethod.id
                    ? '2px solid #019bd1'
                    : '1px solid #000'
                    }`,
                  padding: '16px',
                  borderRadius: '8px',
                }}
                onClick={() => handleSavedCardClick(paymentMethod)}
              >
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    marginBottom: '16px',
                  }}
                >
                  {paymentMethod.brand}
                </p>
                <p style={{ fontSize: '14px' }}>
                  **** **** **** {paymentMethod.last4}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        {isPaymentRequired && showPaymentInfoForm && (
          <Fields>
            <FieldGroup>
              <Label>
                Credit card number
                <StripeInput as={CardNumberElement} />
              </Label>
            </FieldGroup>

            <FieldGroup className={style.sideBySideFieldGroup}>
              <Label>
                Expiration (mm/yy)
                <StripeInput as={CardExpiryElement} />
              </Label>
              <Label>
                CVV
                <StripeInput as={CardCvcElement} />
              </Label>
            </FieldGroup>

            <FieldGroup>
              <Label htmlFor='zip'>ZIP code</Label>
              <Input
                className={style.zipInput}
                id='zip'
                {...register('zip', { required: true })}
              />
            </FieldGroup>

            <FieldGroup>
              <Checkbox
                label='Save card for future payments'
                id='signup-save-card'
                {...register('saveCard', { required: false })}
              />
            </FieldGroup>

            <PaymentMethodIcons />
          </Fields>
        )}

        {error && <Text variant='error'>{error}</Text>}

        {checkoutMutation.isError && (
          <>
            <Text variant='error'>{checkoutMutation.error}</Text>
            <div className={style.errorSpacing}></div>
          </>
        )}

        {isProcessing || checkoutMutation.isLoading ? (
          <Loader />
        ) : (
          <Button type='submit'>Confirm order</Button>
        )}
      </form>
    </>
  )
}

export default Checkout
