import React from 'react'
import PropTypes from 'prop-types'

import { Label, Input, TextArea } from 'components/Forms'
import Text from 'components/Text'
import SmallIntellitagCTA from 'components/SmallIntellitagCTA'
import PhoneInput from 'sharedComponents/Input/Phone'
import style from './style.module.scss'
import Link from 'sharedComponents/Link'

IntellitagForm.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isEditing: PropTypes.bool,
}

function IntellitagForm(props) {
  const { values, onChange, isEditing, hasSubscription, user } = props
  return (
    <>
      {values.tagSize && (
        <>
          {values.intellitags.map((intellitag, index) => (
            <React.Fragment key={intellitag.number}>
              <Label>
                Intellitag ID
                {values.intellitags.length > 1 ? <> #{index + 1}</> : undefined}
              </Label>
              <Input disabled placeholder='Pending' value={intellitag.number} />
              <Text className={style.note} variant='note'>
                {
                  intellitag.pendingReplacementOrder ? <Link to={`/cancel-intellitag-replacement-order/${values.id}/${intellitag.pendingReplacementOrder}`}> Cancel Replacement Order
                  </Link> :
                    <>
                      Lost or damaged Intellitag?{' '}
                      <Link to={`/replace-intellitag?intellitagId=${intellitag.id}`}>
                        Request a replacement
                      </Link>
                      .
                    </>
                }
              </Text>
            </React.Fragment>
          ))}

          <Label htmlFor='pet-name-on-tag'>Intellitag pet name</Label>
          <Input disabled value={values.nameOnTag} id='pet-name-on-tag' />

          <Label htmlFor='pet-email'>Pawrent email</Label>
          <Input
            name='email'
            value={values.email}
            onChange={onChange}
            id='pet-email'
          />

          <Label htmlFor='pet-phone'>Pawrent mobile phone</Label>
          <PhoneInput
            id='pet-phone'
            name='phone'
            value={values.phone}
            onChange={onChange}
          />

          <Label htmlFor='pet-intellitagInstructions'>
            Intellitag lost pet instructions
          </Label>
          <TextArea
            placeholder='Required'
            name='meta.intellitagInstructions'
            value={values.meta.intellitagInstructions}
            onChange={onChange}
            id='pet-intellitagInstructions'
          />

          <Text className={style.note} variant='note'>
            We will send these instructions, with your name and phone number, to
            your pet's rescuer.
          </Text>
        </>
      )}
      {isEditing && !values.tagSize && (
        <SmallIntellitagCTA hasSubscription={hasSubscription} />
      )}
    </>
  )
}

export default IntellitagForm
