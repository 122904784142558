import React from 'react'
import { Link } from 'react-router-dom'

import style from './style.module.scss'

type Props = {
  id: number
  name: string
  intellitagId: null | number
  photo?: {
    sizes: {
      large: {
        src: string
      }
    }
  }
  hasPendingOrder?: boolean
  hasPendingReplacementOrder?: boolean
  intellitags: [
    {
      id: number,
      pendingReplacementOrder: number | null
    }
  ]
  meta?: {
    intellitagInstructions?: boolean
  }
  inactiveIntellitag?: boolean
}

function Pet(props: Props) {
  const {
    id,
    name,
    photo,
    hasPendingOrder,
    hasPendingReplacementOrder,
    meta,
    intellitags,
    intellitagId,
    inactiveIntellitag,
  } = props

  const pendingReplaceOrderIds = intellitags.filter(intellitag => intellitag.pendingReplacementOrder !== null).map(intellitag => intellitag.pendingReplacementOrder)
  
  return (
    <>
      <Link to={`/pets/${id}`} className={style.wrapper}>
        {photo ? (
          <img src={photo.sizes.large.src} />
        ) : (
          <div className={style.placeholder}>Add photo</div>
        )}

        <div className={style.inner}>
          {name}{' '}
          {intellitagId &&
            !inactiveIntellitag &&
            (!photo || !meta.intellitagInstructions) && (
              <span className={style.alert}>!</span>
            )}
        </div>
      </Link>
      {hasPendingOrder && !inactiveIntellitag && (
        <div className={style.orderPendingWrapper}>
          <div className={style.orderPending}>
            <span>Order Pending</span>
          </div>
          <Link to={`/cancel-intellitag-order/${id}`} className={style.cancelOrder}>
            Cancel Order
          </Link>
        </div>
      )}
      {intellitagId && !inactiveIntellitag && (
        <div className={style.orderPendingWrapper}>
          {
            hasPendingReplacementOrder ? (
              <div className={style.orderPending}>Replacement Order Pending</div>
            ) : (
              <div className={style.activatedIntellitag}>Intellitag Activated</div>
            )
          }

          {
            pendingReplaceOrderIds.length > 0 &&  <Link to={`/cancel-intellitag-replacement-order/${id}/${pendingReplaceOrderIds.join(',')}`} className={style.cancelOrder}>
            Cancel Replacement Order
          </Link>
          }
        </div>
      )}
      {inactiveIntellitag && (
        <Link
          to={`/pets/activate-intellitag/reactivate`}
          className={style.activateIntellitagLink}
        >
          Reactivate Intellitag
        </Link>
      )}

      {!intellitagId && !hasPendingOrder && (
        <Link
          className={style.activateIntellitagLink}
          to={`/pets/activate-intellitag?petId=${id}`}
        >
          Order, Replace or Activate Intellitag
        </Link>
      )}
    </>
  )
}

export default Pet