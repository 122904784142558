import React from 'react'
import { connect } from 'react-redux'
import CancelIntellitagOrderModal from './CancelIntellitagOrderModal'

function CancelIntellitagOrderModalContainer(props: any) {
  const { match } = props
  const { petId, orderId } = match.params
  function handleClose() {
    props.history.push(`/`)
  }
  return <CancelIntellitagOrderModal {...props} petId={petId} orderId={orderId} onClose={handleClose} />
}
export default connect()(CancelIntellitagOrderModalContainer)

