import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  Header,
  TextArea,
  Text,
  Label,
  Button,
  FieldGroup,
  Stack,
  Loader,
  Dropdown
} from '@pet-notify/ui'

import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'

type FormData = {
  reasonForCanceling: string
}

type Props = {
  onClose: () => void
}

function DeleteAccountForm({ onClose }: Props) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { setNotification } = useNotification()
  const { deleteAccount } = useUser()
  const { register,formState, ...form } = useForm<FormData>()
  const { errors } = formState
  const { mutate, isLoading } = useMutation(
    (data: FormData) => deleteAccount(data),
    {
      onSuccess: () => {
        setNotification('Account will be permanently deleted')
        onClose()
      },
    },
  )

  const cancellationReasons = [
    { value: 'Rehomed pet', label: 'Rehomed pet' },
    { value: 'Pet passed away', label: 'Pet passed away' },
    { value: 'No longer use', label: 'No longer use' },
    { value: 'Too expensive', label: 'Too expensive' },
    { value: 'Lacks required features', label: 'Lacks required features' },
    { value: 'Purchased another device', label: 'Purchased another device' },
    { value: 'Customer service non-responsive', label: 'Customer service non-responsive' },
  ]

  const handleSubmit = form.handleSubmit((values) => {
    if (showConfirmation) {
      mutate(values)
    } else {
      setShowConfirmation(true)
    }
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header as='h2' mb='6'>
        Delete account
      </Header>

      {showConfirmation ? (
        <>
          <Text mb='4'>
            Are you sure you want to delete your account? This cannot be undone.
          </Text>

          <Stack direction='horizontal' spacing='4'>
            <Button type='submit' variant='red'>
              Yes, delete my data
            </Button>

            <Button type='button' onClick={onClose} variant='hollow'>
              No, keep my account
            </Button>
          </Stack>
        </>
      ) : (
        <>
          <FieldGroup>
            <Label htmlFor='reasonForCanceling'>
              Will you let us know why you’re deleting your account?
            </Label>

            <Dropdown
                name='cancel-reason'
                options={cancellationReasons}
                id='reasonForCanceling'
                {...register('reasonForCanceling', {
                  required: `Please select a reason`,
                })}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  form.setValue('reasonForCanceling', selectedValue);
                }}
                placeholder='Select a reason'
            />

            <div style={{marginBottom:'10px'}}></div>

            {/* <TextArea
              id='reasonForCanceling'
              {...register('reasonForCanceling', { required: true })}
            /> */}

            {errors.reasonForCanceling && (
              <Text variant='error'>{errors.reasonForCanceling.message}</Text>
            )}
          </FieldGroup>
          <Text mb='4'>
            In choosing to delete my account, I understand and agree that all
            Pet Notify products and services, including Intellitag lost pet
            protection monitoring will be discontinued.
          </Text>
          <Text mb='4'>
            Your Pet Notify account and data will be permanently deleted within
            14 days.
          </Text>
          <Stack direction='horizontal' spacing='4'>
            <Button type='submit' variant='red'>
              Delete my account
            </Button>

            <Button type='button' onClick={onClose} variant='hollow'>
              Cancel, keep my free account
            </Button>
          </Stack>
        </>
      )}
    </form>
  )
}
export default DeleteAccountForm
