import mixpanel, { EVENTS } from 'services/mix-panel'

export const SET_INTELLITAGS = 'INTELLITAG_ORDER/SET_INTELLITAGS'
export const SET_SHIPPING_ADDRESS = 'INTELLITAG_ORDER/SET_SHIPPING_ADDRESS'
export const ORDER_SUBMITTED = 'INTELLITAG_ORDER/ORDER_SUBMITTED'
export const SAVED_PAYMENT_METHODS = 'INTELLITAG_ORDER/SAVED_PAYMENT_METHODS'

export function setIntellitags(intellitags) {
  return (dispatch) => {
    dispatch({
      type: SET_INTELLITAGS,
      payload: intellitags,
    })
  }
}

export function setShippingAddress(shippingAddress) {
  return (dispatch) => {
    dispatch({
      type: SET_SHIPPING_ADDRESS,
      payload: shippingAddress,
    })
  }
}

export function submitOrder(data) {
  return async (dispatch, getState, api) => {
    await api.intellitagOrders.create(data)

    mixpanel.track(EVENTS.INTELLITAG_PURCHASE)
    dispatch({
      type: ORDER_SUBMITTED,
    })
  }
}

export function fetchSavedPaymentMethods(data) {
  return async (dispatch, getState, api) => {
    const response = await api.intellitagOrders.savedPaymentMethods(data)

    dispatch({
      type: SAVED_PAYMENT_METHODS,
      payload: response,
    })
  }
}
